<template>
  <div class="bg-white shadow-md rounded-xl py-14">
    <div class="max-w-4xl mx-auto">
      <form @submit.prevent="update" @keydown="form.onKeydown($event)">
        <div class="mb-6">
          <label
            for="email"
            class="text-sm font-medium text-gray-900 block mb-2"
            >Name</label
          >
          <input
            type="email"
            id="email"
            v-model="form.name"
            readonly
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="name@flowbite.com"
            required=""
          />
        </div>

        <!-- <div class="flex justify-end">
          <div class="">
            <button
              @click="$router.push('/settings')"
              class="bg-red-500 text-white py-2 px-6 rounded-xl hover:bg-red-600 ml-3"
            >
              Cancel
            </button>
            <button
              :disabled="form.busy"
              type="submit"
              class="bg-green-500 text-white py-2 px-6 rounded-xl hover:bg-green-600 ml-3"
            >
              Save
            </button>
          </div>
        </div> -->
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import { mapGetters } from "vuex";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",

  layout: "settings",

  data: () => ({
    roles: null,
    form: new Form({
      name: "",
      display_name: "",
      description: "",
      selectedPermissions: [], //
    }),
  }),

  created() {},

  computed: mapGetters({
    user: "auth/user",
  }),
  mounted() {
    if (this.user) {
      this.form.name = this.user.data.name;
    }
  },
  methods: {
    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/roles?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.roles = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>
